<template>
  <div>
    <GridInfoContainer :title="displayData.invoiceInfo" labelWidth="120" labelGap="12">
      <template #default="slotData">
        <InfoContainerItem :data="slotData" :label="displayData.invoiceStatus.label">
          <div class="flex items-center" style="gap: 22px">
            <Tag v-if="showControl.invoiceStatus" :type="displayData.invoiceStatus.tagType">{{ displayData.invoiceStatus.value }}</Tag>
            <BaseElButton v-if="showControl.btn.void" class="text-primary-100 underline" type="text" @click="modal.statusEdit = true">{{ displayData.btn.void }}</BaseElButton>
            <BaseElButton v-if="showControl.btn.manualIssue" class="text-primary-100 underline" type="text" @click="modal.manualIssue = true">{{ displayData.btn.manualIssue }}</BaseElButton>
          </div>
        </InfoContainerItem>
        <InfoContainerItem :data="slotData" :label="displayData.invoiceType.label" :value="displayData.invoiceType.value" />
        <InfoContainerItem :data="slotData" :label="displayData.invoiceNo.label" :value="displayData.invoiceNo.value" />
        <InfoContainerItem :data="slotData" :label="displayData.email.label" :value="displayData.email.value" />
        <InfoContainerItem :data="slotData" v-if="showControl.companyName" :label="displayData.companyName.label" :value="displayData.companyName.value" />
        <InfoContainerItem :data="slotData" v-if="showControl.taxId" :label="displayData.taxId.label" :value="displayData.taxId.value" />
      </template>
    </GridInfoContainer>

    <WarningDialog
      v-if="modal.manualIssue"
      title="手動開立發票"
      content="是否確認手動開立發票"
      @confirm="manualIssue"
      @close="modal.manualIssue = false"
    />

    <MemberShopOrderInvoiceStatusEditModal
      v-if="modal.statusEdit"
      :orderId="get(order, 'id')"
      @close="modal.statusEdit = false"
      @done="$emit('refresh'), modal.statusEdit = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import MemberShopOrderInvoiceStatusEditModal from './MemberShopOrderInvoiceStatusEditModal.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { get } from 'lodash'
import { invoiceStatusConfig } from '@/config/memberShop'
import { MemberShopOrderIssueInvoice } from '@/api/memberShop'
import Tag from '@/components/Tag/Tag.vue'
import store from '@/store'
import { useTransitionCode } from '../utils'

export default defineComponent({
  name: 'MemberShopOrderInvoiceInfoSection',
  components: { GridInfoContainer, InfoContainerItem, Tag, MemberShopOrderInvoiceStatusEditModal, WarningDialog },
  props: {
    order: { type: Object, default: () => ({}) },
    actions: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const displayInvoiceStatus = computed(() => {
      const status = get(props.order, 'MemberStoreOrderInvoice.status')
      if (!status) return null
      return get(invoiceStatusConfig, status)
    })
    const displayIinvoiceType = computed(() => {
      const invoiceType = get(props.order, 'MemberStoreOrderInvoice.InvoiceContent.buyer.type')
      if (!invoiceType) return '-'
      if (invoiceType === 'B') return '三聯式發票'
      return '二聯式發票'
    })
    const checkCode = (code) => {
      const actions = props.actions
      const { actionCode } = useTransitionCode(actions)
      return actionCode(code)
    }
    const showControl = computed(() => {
      const invoiceType = get(props.order, 'MemberStoreOrderInvoice.InvoiceContent.buyer.type')
      const control = {
        invoiceStatus: displayInvoiceStatus.value,
        btn: {
          void: checkCode('ACT_INVOICE_RETURNED') && checkCode('ACT_INVOICE_VOIDED'),
          manualIssue: checkCode('ACT_INVOICE_ISSUED'),
        },
        companyName: invoiceType === 'B',
        taxId: invoiceType === 'B',
      }
      return control
    })
    const displayData = computed(() => {
      const memberStoreOrderInvoice = get(props.order, 'MemberStoreOrderInvoice')
      const buyer = get(memberStoreOrderInvoice, 'InvoiceContent.buyer')
      const data = {
        invoiceInfo: '發票資訊',
        invoiceStatus: {
          label: '發票狀態',
          value: get(displayInvoiceStatus.value, 'label'),
          tagType: get(displayInvoiceStatus.value, 'tagType'),
        },
        invoiceType: {
          label: '發票種類',
          value: displayIinvoiceType.value,
        },
        invoiceNo: {
          label: '發票號碼',
          value: get(memberStoreOrderInvoice, 'invoiceNo') || '-',
        },
        email: {
          label: '電子信箱',
          value: get(buyer, 'email') || '-',
        },
        companyName: {
          label: '公司抬頭',
          value: get(buyer, 'name') || '-',
        },
        taxId: {
          label: '統一編號',
          value: get(buyer, 'identifier') || '-',
        },
        btn: {
          void: '折讓/作廢',
          manualIssue: '手動開立',
        }
      }
      return data
    })
    const modal = reactive({
      statusEdit: false,
      manualIssue: false,
    })

    const manualIssue = async () => {
      const [, err] = await MemberShopOrderIssueInvoice({
        shopId: shopId.value,
        id: get(props.order, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('已手動開立發票！')
      emit('refresh')
      modal.manualIssue = false
    }
    return { get, displayInvoiceStatus, modal, manualIssue, checkCode, showControl, displayData }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-input {
  @apply w-[150px];
}
</style>
